import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { withTrailingSlash } from '@origin8-web/o8-utils/format';
import { RepresentativeStatus } from 'common.interfaces';
import { map, Observable, of, switchMap } from 'rxjs';
import { O8ConfigService } from '../config';
import { UserService } from '../login/providers';
import { SseService } from '../sse';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LiveStatusService {
  readonly sseService = inject(SseService);
  readonly o8ConfigService = inject<O8ConfigService<{ securedApiGateway: string }>>(O8ConfigService);
  readonly userService = inject(UserService);
  readonly platformId = inject(PLATFORM_ID);

  getAdvocatesLiveStatus(): Observable<RepresentativeStatus[]> {
    if (isPlatformServer(this.platformId)) {
      return of([]);
    }

    const url =
      withTrailingSlash(this.o8ConfigService.get('securedApiGateway') as string) +
      'advocate/get-live-status-signed-url';
    return this.sseService.subscribeToSecureSse<RepresentativeStatus[]>(url);
  }

  getAdvocateLiveStatus(userTalkdeskIdOrName: string): Observable<RepresentativeStatus | undefined> {
    return this.getAdvocatesLiveStatus().pipe(
      map((status) => {
        return status.find((s) => {
          return s.user_id === userTalkdeskIdOrName || s.name === userTalkdeskIdOrName;
        });
      }),
    );
  }

  getCurrentUserLiveStatus(): Observable<RepresentativeStatus | undefined> {
    return this.userService.getSelfOrImpersonatedUser$().pipe(
      map((u) => u?.talkdeskUserId ?? u?.name ?? ''),
      switchMap((userTalkdeskIdOrName) => this.getAdvocateLiveStatus(userTalkdeskIdOrName)),
    );
  }
}
